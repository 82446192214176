import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {borderRadius, truncate} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";
import {IFormFieldProps} from "@pg-mono/form";
import {isEqual} from "@pg-mono/nodash";

import {DropdownToOpen} from "../../constants/DropdownToOpen";
import {fadeInAnimation} from "../atoms/atoms";
import {SearchDropdownArrow} from "../atoms/SearchDropdownArrow";

interface IRange<T> {
    lower: T;
    upper: T;
    bounds?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars
export interface IFilterChildProps<TRangeValue> extends IFormFieldProps<string, IRange<any>> {
    clearField: () => void;
    errorOnBottom?: boolean;
    toggleDropdown: () => void;
    label: string;
}

interface IProps<TRangeValue> extends IFormFieldProps<string, IRange<TRangeValue>> {
    children: (props: IFilterChildProps<TRangeValue>) => JSX.Element;
    disableClearAction?: boolean;
    dropdownName: DropdownToOpen;
    errorOnBottom?: boolean;
    fullWidth?: boolean;
    getLabel: (value: IRange<TRangeValue>, defaultLabel: string, hideLabelOnValue?: boolean) => string | JSX.Element;
    hideLabelOnValue?: boolean;
    id: string;
    isDeactivated?: boolean;
    isDropdownOpened: boolean;
    isMobileDropdown?: boolean;
    isMobileWidth: boolean;
    label: string;
    labelId?: string;
    noDropdown?: boolean;
    onClearField?: () => void;
    rightDropdown?: boolean;
    toggleDropdown: (dropdownToOpen: DropdownToOpen | null) => void;
}

export class RangeFilter<T> extends React.PureComponent<IProps<T | "">, {}> {
    private previousChangedValue: IRange<T | ""> | null = null;

    private onAfterChangeValidated = (name: string, value: IRange<T | "">) => {
        if (!isEqual(value, this.previousChangedValue)) {
            this.props.onAfterChange(this.props.name, value);
            this.previousChangedValue = value;
        }
    };

    private clearField = () => {
        this.props.onClearField && this.props.onClearField();
        this.props.onChange(this.props.name, {lower: "", upper: ""});
        this.onAfterChangeValidated(this.props.name, {lower: "", upper: ""});
    };

    private onLabelClick = () => {
        this.props.toggleDropdown(this.props.dropdownName);
        this.onAfterChangeValidated(this.props.name, this.props.value);
    };

    private onClearFieldClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        this.clearField();
        this.props.toggleDropdown(null);
    };

    private onUpArrowClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        this.props.toggleDropdown(null);
    };

    private onKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape") {
            this.props.toggleDropdown(null);
        }

        if (e.key === "Enter") {
            this.props.onChange(this.props.name, this.props.value);
            this.onAfterChangeValidated(this.props.name, this.props.value);
            this.props.toggleDropdown(null);
        }
    };

    componentDidUpdate(prevProps: Readonly<IProps<"" | T>>) {
        if (prevProps.isDropdownOpened && !this.props.isDropdownOpened) {
            this.onAfterChangeValidated(this.props.name, this.props.value);
        }
    }

    /**
     * Render
     */

    public render() {
        const {isDropdownOpened, value} = this.props;

        const renderEnchancedChild = () =>
            this.props.children({
                clearField: this.clearField,
                error: this.props.error,
                errorOnBottom: this.props.errorOnBottom,
                name: this.props.name,
                onAfterChange: this.onAfterChangeValidated,
                onChange: this.props.onChange,
                toggleDropdown: () => this.props.toggleDropdown(null),
                label: this.props.label,
                value: value
            });

        if (this.props.noDropdown) {
            return renderEnchancedChild();
        }

        return (
            <div css={filterHolder} onKeyDown={this.onKeyDownHandler}>
                <div
                    css={[filterLabel, this.props.isMobileDropdown && filterLabelStyle]}
                    tabIndex={-1}
                    onClick={this.props.isDeactivated ? () => null : this.props.isDropdownOpened ? this.onUpArrowClick : this.onLabelClick}
                >
                    <Text variant="body_copy_2" css={[filterLabelText, truncate]}>
                        {this.props.getLabel(value, this.props.label, this.props.hideLabelOnValue)}
                    </Text>

                    {!this.props.disableClearAction && (value.lower || value.upper) ? (
                        <div onClick={(e) => this.onClearFieldClick(e)}>
                            <CloseIcon size="1.4" />
                        </div>
                    ) : (
                        <SearchDropdownArrow isDown={this.props.isDropdownOpened} />
                    )}
                </div>

                {(isDropdownOpened || this.props.isMobileWidth) && (
                    <ChildHolder rightDropdown={this.props.rightDropdown} isMobileDropdown={!!this.props.isMobileDropdown} fullWidth={!!this.props.fullWidth}>
                        {renderEnchancedChild()}
                    </ChildHolder>
                )}
            </div>
        );
    }
}

export const filterHolder = (theme: Theme) => css`
    position: relative;
    width: 100%;

    @media (max-width: calc(${theme.breakpoints.md} - 1px)) {
        padding: 0;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
`;

export const filterLabelStyle = css`
    padding: 0 1.5rem;
    height: 4.8rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
export const filterLabel = (theme: Theme) => css`
    display: none;

    &:focus {
        outline: none;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        ${filterLabelStyle};
    }
`;

export const filterLabelText = css`
    font-size: 1.4rem;
    padding-right: 1rem;
    white-space: nowrap;
`;

interface IChildHolderProps {
    rightDropdown?: boolean;
    isMobileDropdown: boolean;
    fullWidth?: boolean;
}

const childHolderStyle = (rightDropdown: boolean, fullWidth?: boolean) => css`
    ${fadeInAnimation("0.2s")};

    position: absolute;
    top: 6rem;
    z-index: 1001;
    right: ${rightDropdown ? 0 : "unset"};
    left: ${rightDropdown ? "unset" : 0};

    padding: 2rem 3rem;
    ${borderRadius(4)}
    min-width: ${fullWidth ? "100%" : "36rem"};
    max-height: 60rem;

    width: ${fullWidth ? "100%" : "initial"};
    max-width: ${fullWidth ? "100%" : "initial"};
`;
export const ChildHolder = styled.div<IChildHolderProps>`
    background: #fff;
    padding: 1rem 2rem;

    .range-input-lower,
    .range-input-upper {
        margin-bottom: 10px;
    }

    ${({isMobileDropdown, rightDropdown, fullWidth}) => isMobileDropdown && childHolderStyle(!!rightDropdown, !!fullWidth)};
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        ${(props) => childHolderStyle(!!props.rightDropdown)};
    }
`;
